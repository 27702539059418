@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --sidebar-flex:1.5;
  --condiv-flex:6;
  --fontsize-p:1.2rem;
  --fontsize-nav:1.6rem;
  --typing-font:3rem;
}
*
{
  margin:0;
  padding: 0;
}
body{
  font-family: 'Raleway';
}
.App
{
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
nav
{
  padding:60px 30px 0px 30px ;
  flex:1.5;
  flex:var(--sidebar-flex);
  background: rgb(238,103,143);
  background: linear-gradient(180deg, rgb(103, 243, 84) 50%, rgb(53, 39, 245) 80%);
}
.condiv
{
  align-self: center;
  padding: 100px ;
  background-color: #f4f5f9;
  flex:6;
  flex:var(--condiv-flex);
}

.home
{
  display: flex;
  justify-content: center; 
  flex-direction: column;
  align-items: center;
  
}
.profilepic
{
  border-radius: 50%;
  width: 200px;
  margin: 30px;
}
.typingeffect
{
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 3rem;
  font-size: var(--typing-font);
  margin-bottom: 30px;
}

nav ul
{
  text-shadow:#080808;
  font-size:1.6rem;
  font-size:var(--fontsize-nav) ;
  text-align: center;
  list-style-type: none;
  text-decoration: none;
  color: white !important;
}
nav ul li
{
  
  margin:40px;
}

a
{
  align-self: center;
  padding-right: 10px;
  text-decoration: none;
  color: rgb(8, 96, 228);
}

.subtopic
{
  margin:10px 10px 30px 10px !important;
}
p{
  font-size: 1.2rem;
  font-size: var(--fontsize-p);
}
h1,h2,h3,h4,p{
  margin: 10px;
}
.social
{
  align-self: center;
  position: inherit;
  padding-right: 20px;
  margin-top: 20px;
  display: flex;
  bottom: 60px;
}
.social i
{
  align-self: center  ;
  padding-right: 50px;
  font-size: 25px;
  color: #9a9b9c;
}
.social i:hover
{
  
  color: #ffffff;
}
.widecard
{
  border:0px solid #9a9b9c;
  display: flex;
  margin:30px 0px 30px 0px;
}
.widecard:hover
{
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.active
{
  font-weight: bolder;
}
.skills ul li
{
  list-style-type:none;
  margin:30px;
}

@media all and (max-width: 450px) {
  :root {
    --fontsize-nav:1rem;
    --sidebar-flex:0;
    --typing-font:2rem;
  }
  .App
  {
    flex-direction: column;
  }
  .sidebar
  {
    padding: 30px;
    background: rgb(238,103,143);
    background: linear-gradient(90deg, rgba(238,103,143,1) 50%, rgba(132,124,252,1) 80%);
  }
  .condiv
  {
    padding: 50px;
  }
  nav ul
  {
    display: flex;
  }
  nav ul li
  {
    margin: 10px;
  }
  /* .social
  {
    position:inherit;
    left:0;
    padding-right: 20px;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    bottom: 30px;
  }
  .social i
  {
    padding-right: 0px;
  } */
  .github, .twitter, .linkedin{
    padding-right: 20px;
    align-items: center;
  }
}
